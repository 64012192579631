import styled from "@emotion/styled";

export const PageHeader = styled.div`
  padding: 120px 30px 60px;
  margin-top: -100px;
`;

export const PageTitle = styled.h1`
  font-size: 72px;
  font-weight: 800;
  text-align: center;
  margin: 0;
`;

export const BlogPostsWrapper = styled.div`
  padding: 100px 30px;
  background-color: #eee;
`;

export const BlogPosts = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  max-width: 800px;
  margin: 0 auto;
`;

export const BlogPost = styled.div`
  /* flex-basis: 50%;
  width: 50%; */
  & + & {
    margin-top: 40px;
  }
`;

export const BlogPostTitle = styled.h2`
  font-size: 32px;
  font-weight: 800;
  margin: 0 0 1em;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #626567;
  }

  &:hover:after {
    background-color: #626567;
  }

  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 50px;
    background-color: #333;
    margin-top: 8px;
    transform: scale(1, 1);
    transform-origin: left top;
    transition: 0.2s ease-in-out;
  }
`;

export const BlogPostContent = styled.div`
  padding: 30px 20px;

  font-family: Lato;
`;

export const BlogPostInner = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  transform: scale(1);
  transition: 0.2s ease-in-out;
  /* overflow: hidden; */
  cursor: pointer;

  a.post-link-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    transform: scale(1.0018);
    ${BlogPostTitle}:after {
      transform: scale(2, 0.6);
    }
  }
`;

export const BlogPostImage = styled.div`
  width: 200px;
  min-width: 200px;
  min-height: 200px;
  margin-right: 20px;
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
  }

  .blog-post-thumbnail {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const BlogPostExcerpt = styled.div`
  font-size: 18px;
`;

export const BlogPostReadMore = styled.div`
  margin-top: 20px;

  .arrow {
    display: inline-block;
    transition: 0.2s;
    transform: translateX(0);
  }

  a:hover .arrow {
    transform: translateX(10px);
  }

  a {
    font-size: 18px;
    display: inline-block;
    color: #333;
    text-decoration: none;
  }
`;
