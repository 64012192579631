import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import Container from "../components/LayoutComponents/Container";
import Seo from "../components/seo";
import {GatsbyImage} from "gatsby-plugin-image";

import {
  PageHeader,
  BlogPost,
  BlogPostContent,
  BlogPostExcerpt,
  BlogPostImage,
  BlogPostInner,
  BlogPostReadMore,
  BlogPostTitle,
  BlogPosts,
  BlogPostsWrapper,
  PageTitle,
} from "./blog.styles";

const BlogIndexPage = ({data}) => {
  const blogPosts = data.allStrapiBlogPosts.edges.map(({node}) => ({...node}));
  return (
    <>
      <Layout>
        <Seo title="Singerly" />
        <PageHeader>
          <Container>
            <PageTitle>Blog</PageTitle>
          </Container>
        </PageHeader>
        <BlogPostsWrapper>
          <Container>
            <BlogPosts>
              {blogPosts.map((blogPost, key) => (
                <BlogPost key={key}>
                  <BlogPostInner>
                    <BlogPostImage>
                      <GatsbyImage
                        image={
                          blogPost.featured_image.childImageSharp
                            .gatsbyImageData
                        }
                        alt={blogPost.title}
                        className="blog-post-thumbnail"
                      />
                    </BlogPostImage>
                    <BlogPostContent>
                      <BlogPostTitle>{blogPost.title}</BlogPostTitle>
                      <BlogPostExcerpt>{blogPost.excerpt}</BlogPostExcerpt>
                      <BlogPostReadMore>
                        <Link to={`/blog/${blogPost.slug}`}>
                          Read more<span className="arrow">&rarr;</span>
                        </Link>
                      </BlogPostReadMore>
                      <Link
                        to={`/blog/${blogPost.slug}`}
                        className="post-link-overlay"
                      />
                    </BlogPostContent>
                  </BlogPostInner>
                </BlogPost>
              ))}
            </BlogPosts>
          </Container>
        </BlogPostsWrapper>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query BlogIndexQuery {
    allStrapiBlogPosts {
      edges {
        node {
          id
          slug
          featured_image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
          title
          content
          excerpt
        }
      }
    }
  }
`;

export default BlogIndexPage;
